import React from "react"
import SEO from "../utils/seo"
import styled from "styled-components"
import { Link } from "gatsby"

const Wrapper = styled.div`
    ${props => props.theme.wrappersContainers.primaryWrapper};
    ${props => props.theme.wrappersContainers.primaryContainer};
`

const NotFoundPage = () => (
    <>
        <SEO title="404: Not found" />
        <Wrapper>
            <h2>Sorry, we can't find the page you are looking for.</h2>
            <p>Click <Link to="/" title="Home">here</Link> to go back to the homepage</p>
        </Wrapper>
    </>
)

export default NotFoundPage
